@import "../styles/fonts.scss";

#video-background {
    z-index: 100;
    pointer-events: none;
    overflow: hidden;
}

.video-wrapper {
    position: relative;
    width: 100vw;
    height: calc(100vh - 90px);
    z-index: 100;
    pointer-events: none;
    overflow: hidden;
    background: black;
}
.video-wrapper iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.nest-fleet {
    background: rgb(237, 237, 240);
    h2 {
        border-top: 1px solid black;
        padding-top: 2rem;
        font-family: $vbfont;
    }
    a.nbtn {
        border: 1px solid black;
        padding: 0.5rem 1rem;
        color: black;
        font-weight: 600;
        letter-spacing: 5px;
        font-size: 1rem;
        text-transform: uppercase;
        text-decoration: unset;
        &:hover, &:focus {
            text-decoration: unset;
            border: 2px solid black;
            color: black;
        }
    }
    .c-slide {
        @media (max-width: 768px) {
            padding: 0px !important;
        }
        a {
            height: 100%;
            width: 100%;
            color: black;
            text-decoration: unset;
            &:hover {
                color: black;
                text-decoration: unset;
            }
        }
    }
    .c-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 80%;
        width: 100%;
        border-radius: 5px 5px 0px 0px;
    }
    .c-info {
        display: flex;
        text-align: center;
        justify-content: center;
        text-transform: uppercase;
        border-radius: 0px 0px 5px 5px;
        background: white;
        div {
            flex: 1;
        }
    }
    .c-vname {
        font-family: $vbfont;
        white-space: pre;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .carousel__back-button, .carousel__next-button {
        position: absolute;
        top: calc(50% - 25px);
        color: gray;
        background: transparent;
        padding: 0px;
        border: none;
        text-shadow: rgba(20, 20, 20, .5) 0px 1px 1px;
        &:disabled {
            // opacity: 0.3;
            display: none !important;
        }
        &:hover {
            -webkit-text-stroke: 1px black;
        }
        @media (max-width: 900px) {
            display: block;
        }
    }
    .carousel__next-button {
        right: -50px;
        -webkit-appearance: unset;
    }
    .carousel__back-button {
        left: -50px;
        -webkit-appearance: unset;
    }
    @media (max-width: 768px) {
        .carousel__next-button {
            right: -47.5px;
            -webkit-appearance: unset;
        }
        .carousel__back-button {
            left: -47.5px;
            -webkit-appearance: unset;
        }
    }
}

.nest-quality {
    background: rgb(237, 237, 240);
    h2 {
        border-top: 1px solid black;
        padding-top: 2rem;
        font-family: $vbfont;
    }
    a.nbtn {
        border: 1px solid black;
        padding: 0.5rem 1rem;
        color: black;
        font-weight: 600;
        letter-spacing: 5px;
        font-size: 1rem;
        text-transform: uppercase;
        text-decoration: unset;
        &:hover, &:focus {
            text-decoration: unset;
            border: 2px solid black;
            color: black;
        }
    }
    .c-slide {
        @media (max-width: 768px) {
            padding: 0px !important;
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            color: black;
            text-decoration: unset;
            &:hover {
                color: black;
                text-decoration: unset;
            }
        }
        
    }

    .c-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 80%;
        width: 100%;
        border-radius: 5px 5px 0px 0px;
    }
    .carousel__back-button, .carousel__next-button {
        position: absolute;
        top: calc(50% - 25px);
        color: gray;
        padding: 0px;
        background: transparent;
        border: none;
        text-shadow: rgba(20, 20, 20, .5) 0px 1px 1px;
        &:disabled {
            // opacity: 0.3;
            display: none !important;
        }
        &:hover {
            -webkit-text-stroke: 1px black;
        }
        @media (max-width: 900px) {
            display: block;
        }
    }
    .carousel__next-button {
        right: -40px;
        -webkit-appearance: unset;
        @media (max-width: 768px) {
            right: -50px;
            display: none;
        }
    }
    .carousel__back-button {
        left: -50px;
        -webkit-appearance: unset;
        @media (max-width: 768px) {
            left: -50px;
            display: none;
        }
    }
}

.home-sell-buy {
    height: 400px;
    display: flex;
    max-width: 1680px;
    margin: auto;
    @media (max-width: 480px) {
        flex-direction: column;
        height: auto;
    }
    .home-sell-container, .home-buy-container {
        position: relative;
        height: 100%;
        width: 49.5%;
        @media (max-width: 480px) {
            width: 100%;
            height: 300px;
        }
    }
    .home-sell-container {
        margin-right: auto;
        @media (max-width: 480px) {
            margin-right: unset;
        }
    }
    .home-buy-container {
        margin-left: auto;
        @media (max-width: 480px) {
            margin-left: unset;
        }
    }
    .home-sell, .home-buy {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        a {
            border: 1px solid white;
            padding: 0.5rem 4rem;
            color: white;
            font-weight: 600;
            letter-spacing: 5px;
            font-size: 1.5rem;
            text-transform: uppercase;
            text-decoration: unset;
            &:hover, &:focus {
                text-decoration: unset;
                border: 2px solid white;
            }
        }
    }
}

.home-charter {
    .home-charter-inner {
        max-width: 768px;
        text-align: center;
        h2 {
            padding-top: 2rem;
            font-family: $vbfont;
        }
        a {
            border: 1px solid black;
            padding: 0.5rem 1rem;
            color: black;
            font-weight: 600;
            letter-spacing: 5px;
            font-size: 1rem;
            text-transform: uppercase;
            text-decoration: unset;
            &:hover, &:focus {
                text-decoration: unset;
                border: 2px solid black;
                color: black;
            }
        }
    }
}

.home-charter-video {
    padding: 0rem 1rem 2rem;
    iframe {
        height: 500px;
        width: 100%;
        @media (max-width: 768px) {
            height: 200px;
        }
    }
}
