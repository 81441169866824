@import "../styles/fonts.scss";

.header {
    background: black;
    color: white;
    text-transform: uppercase;
    text-decoration: unset;
    font-family: $futura;
    font-weight: 600;
    letter-spacing: 2px;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    a {
        text-decoration: unset;
        &:hover, &:focus {
            color: white;
        }
    }
    a.nav-link {
        margin-left: 1rem !important;
        @media (max-width: 1000px) {
            margin-left: unset !important;
        }
        @media (max-width: 480px) {
            padding-left: 1rem;
        }
    }
    .navbar-brand {
        img {
            height: 40px;
            @media (max-width: 480px) {
                height: 30px;
                margin-left: 1rem;
            }
        }
    }
    @media (max-width: 480px) {
        .navbar-toggler {
            border: transparent;
            box-shadow: unset;
            &:hover, &:focus {
                border: transparent;
                box-shadow: unset;
            }
        }
        .navbar-collapse {
            padding-top: 1rem;
        }
    }
    @media (max-width: 1200px) {
        font-size: 0.8rem;
    }
}