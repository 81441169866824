@import "../styles/fonts.scss";

.about {
    .page-banner {
        min-height: 600px;
        background-size: cover;
        background-color: black;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        @media (max-width: 768px) {
            min-height: 300px;
        }
    }
    h1 {
        font-family: $vbfont;
        text-transform: uppercase;
        font-size: 4rem;
        letter-spacing: 5px;
        @media (max-width: 480px) {
            font-size: 2rem;
        }
    }
    h2 {
        font-family: $vbfont;
        text-transform: uppercase;
        letter-spacing: 5px;
        margin-bottom: 2rem;
    }
    .about-powerhouse {
        overflow: hidden;
        height: 750px;
        background: linear-gradient(125deg, transparent, transparent 50%, black 0);
        @media (max-width: 1400px) {
            background: white;
            height: auto;
        }
        .about-inner {
            height: 100%;
            flex-direction: column;
        }
        .about-powerhouse-left {
            max-width: 50%;
            margin-right: auto;
            padding: 1rem;
            margin-top: 2rem;
            line-height: 2rem;
            @media (max-width: 1400px) {
                margin-right: unset;
                max-width: 100%;
            }
        }
        .about-powerhouse-right {
            max-width: 50%;
            position: relative;
            margin-left: auto;
            margin-top: auto;
            padding: 1rem;
            @media (max-width: 1400px) {
                background: black;
                max-width: 100%;
                margin-left: unset;
                margin-top: unset;
                text-align: center;
            }
            .about-world {
                position: absolute;
                top: -400px;
                right: -20%;
                height: 400px;
                @media (max-width: 1400px) {
                    position: relative;
                    height: auto;
                    max-width: 100%;
                    width: 300px;
                    top: unset;
                    right: unset;
                }
                @media (max-width: 768px) {
                    height: 200px;
                    width: auto;
                    margin-bottom: 0.5rem;
                }
            }
            .ceo {
                position: absolute;
                top: -250px;
                left: -500px;
                height: 600px;
                @media (max-width: 1400px) {
                    position: relative;
                    height: auto;
                    max-height: 100%;
                    height: 300px;
                    top: unset;
                    left: unset;
                    margin-right: 5rem;
                }
                @media (max-width: 768px) {
                    height: 200px;
                    margin-right: 1rem;
                    margin-bottom: 0.5rem;
                    display: none;
                }
            }
            p {
                color: white;
                @media (max-width: 768px) {
                    margin-top: 2rem;
                }
            }
        }
    }
    .about-video {
        margin-top: 2rem;
        padding: 2rem 1rem;
        iframe {
            height: 500px;
            width: 100%;
            @media (max-width: 768px) {
                height: 200px;
            }
        }
        p {
            padding: 1rem 0px;
            max-width: 950px;
            margin: auto;
        }
    }
}