@import "../styles/fonts.scss";

.charter {
    .page-banner {
        min-height: 600px;
        background-size: cover;
        background-color: black;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        flex-direction: column;
        @media (max-width: 768px) {
            min-height: 300px;
        }
        a {
            border: 1px solid #fff;
            color: #fff;
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: 5px;
            padding: 0.25rem 3rem;
            text-decoration: none;
            text-decoration: initial;
            text-transform: uppercase;
            margin-top: 2rem;
        }
    }
    h1 {
        font-family: $vbfont;
        text-transform: uppercase;
        font-size: 4rem;
        letter-spacing: 5px;
        @media (max-width: 480px) {
            font-size: 2rem;
        }
    }
    h2 {
        font-family: $vbfont;
        text-transform: uppercase;
        letter-spacing: 5px;
        margin-bottom: 2rem;
    }
    h3 {
        font-family: $vbfont;
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: 5px;
        margin-bottom: 2rem;
    }
    .charter-explore {
        line-height: 2rem;
        p {
            max-width: 950px;
            margin: auto;
        }
    }
    .charter-find {
        line-height: 2rem;
        text-align: center;
        background: black;
        color: white;
        p {
            max-width: 950px;
            margin: auto;
        }
        img {
            width: 100%;
            height: auto;
            border-radius: 5px;
            margin-bottom: 2rem;
        }
    }
    .charter-video {
        // margin-top: 2rem;
        padding: 2rem 1rem;
        iframe {
            height: 500px;
            width: 100%;
            @media (max-width: 768px) {
                height: 200px;
            }
        }
        p {
            padding: 1rem 0px;
            max-width: 950px;
            margin: auto;
        }
    }
    .charter-contact {
        background-color: rgb(47, 47, 47);
        color: white;
        .contact-us__inner {
            max-width: 798px;
        }
        textarea {
            height: 200px;
        }
        .btn {
            padding: 0.5rem 2rem;
            text-transform: uppercase;
            margin-top: 1rem;
        }
        label.form-check-label {
            padding: 1rem 0px;
            text-align: left;
        }
        .contact-error {
            border: 1px solid white;
        }
    }
}