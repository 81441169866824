.footer {
    background: black;
    padding: 1rem;
    color: white;
    font-size: 0.75rem;
    .te {
        text-align: right;
        @media (max-width: 992px) {
            text-align: unset;
        }
    }
    .footer-br {
        border-right: 1px solid rgba(100, 100, 100, 0.6);
        @media (max-width: 992px) {
            border-right: unset;
        }
    }
    .footer-bottom {
        text-transform: uppercase;
    }
    .tc-mob {
        @media (max-width: 992px) {
            text-align: center;
        }
    }
    a {
        color: white;
        text-decoration: unset;
        &:hover, &:focus {
            color: white;
            opacity: 0.8;
        }
    }
}