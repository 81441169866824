@font-face {
    font-family: "Biondi Sans Custom";
    src: url("../fonts/biondi-sans-custom.woff") format("woff"),
        url("../fonts/biondi-sans-custom.ttf") format('truetype');
}

@font-face {
    font-family: "Futura PT Light";
    src: url("../fonts/FuturaPTBook.woff") format("woff"),
        url("../fonts/FuturaPTBook.ttf") format('truetype');
}

@font-face {
    font-family: "Object Sans Heavy";
    src: url("../fonts/ObjectSans-Heavy.woff") format("woff");
}

@font-face {
    font-family: "Object Sans Heavy Slanted";
    src: url("../fonts/ObjectSans-HeavySlanted.woff") format("woff");
}

@font-face {
    font-family: "Object Sans Regular";
    src: url("../fonts/ObjectSans-Regular.woff") format("woff");
}

@font-face {
    font-family: "Object Sans Regular Slanted";
    src: url("../fonts/ObjectSans-Slanted.woff") format("woff");
}

@font-face {
    font-family: "Vanitas Bold";
    src: url("../fonts/Vanitas-Bold.woff") format("woff");
}

@font-face {
    font-family: "Vanitas Black";
    src: url("../fonts/Vanitas-Black.woff") format("woff");
}

@font-face {
    font-family: "Roboto Thin";
    src: url("../fonts/Roboto-Thin.woff") format("woff");
}


$cfont: "Biondi Sans Custom";
$osh: "Object Sans Heavy";
$oshs: "Object Sans Heavy Slanted";
$osr: "Object Sans Regular";
$osrs: "Object Sans Regular Slanted";
$vfont: "Vanitas Bold";
$vbfont: "Vanitas Black";
$rbt: "Roboto Thin";
$futura: "Futura PT Light";

$font-family-base: $futura, "Helvetica Neue Light", "Helvetica Neue", "Arial", "Helvetica", "sans-serift" !important;