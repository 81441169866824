@import "../styles/fonts.scss";

.sell {
    .page-banner {
        min-height: 600px;
        background-size: cover;
        background-color: black;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        @media (max-width: 768px) {
            min-height: 300px;
        }
    }
    h1 {
        font-family: $vbfont;
        text-transform: uppercase;
        font-size: 4rem;
        letter-spacing: 5px;
        @media (max-width: 480px) {
            font-size: 2rem;
        }
    }
    h2 {
        font-family: $vbfont;
        text-transform: uppercase;
        letter-spacing: 5px;
        margin-bottom: 2rem;
    }
    .sell-about {
        line-height: 2rem;
        img {
            width: 100%;
            height: auto;
            border-radius: 5px;
            max-width: 400px;
        }
        p {
            max-width: 950px;
            margin: auto;
        }
    }
    .sell-marketing {
        line-height: 2rem;
        text-align: center;
        background: black;
        color: white;
        p {
            max-width: 950px;
            margin: auto;
        }
        img {
            width: 100%;
            height: auto;
            border-radius: 5px;
            margin-bottom: 2rem;
        }
    }
    .sell-title {
        h2 {
            margin-bottom: 0rem;
        }
    }
    .sell-contact {
        background-color: rgb(47, 47, 47);
        color: white;
        .contact-us__inner {
            max-width: 798px;
        }
        textarea {
            height: 200px;
        }
        .btn {
            padding: 0.5rem 2rem;
            text-transform: uppercase;
            margin-top: 1rem;
        }
        label.form-check-label {
            padding: 1rem 0px;
            text-align: left;
        }
        .contact-error {
            border: 1px solid white;
        }
    }
}